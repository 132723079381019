<script setup lang="ts">
import { PropType } from 'vue'
import { TranslationKey } from '~/i18n/TranslationKeys'
import { ButtonSizes } from '~~/src/constants/buttonSizes'
import { EmitsEnum } from '~~/src/constants/emits'

const props = defineProps({
	entries: {
		type: Object as PropType<TranslationKey[]>,
		default: [],
	},
	testIds: {
		type: Object as PropType<string[]>,
		default: [],
	},
	selectedOption: { type: Number, default: 0 },
	disabledOptions: { type: Array as PropType<number[]>, default: [] },
})
const { entries, selectedOption, testIds, disabledOptions } = toRefs(props)
const emit = defineEmits([EmitsEnum.Click])

const computedClasses = computed(() => (index: number) => {
	if (selectedOption.value === index) {
		return 'bg-main-blue-500 text-white'
	}

	if (disabledOptions.value.includes(index)) {
		return 'text-secondary-gray-600 cursor-not-allowed'
	} else {
		return 'text-main-blue-600 hover:bg-main-blue-200 hover:text-secondary-gray-800'
	}
})

const onClick = (index: number) => {
	emit(EmitsEnum.Click, index)
}
</script>

<template>
	<div class="flex w-full items-center gap-1 rounded-md bg-main-blue-50 px-0.5 py-0.5 shadow">
		<CommonButton
			v-for="(entry, index) in entries"
			:key="entry"
			:data-testid="testIds?.[index] ?? entry"
			:size="ButtonSizes.TEXT_M"
			@click="onClick(index)"
			class="h-8 w-full rounded py-1.5"
			:class="computedClasses(index)"
			ripple-color-override="bg-light-blue-200"
			:disabled="disabledOptions.includes(index)"
		>
			<CommonText :text="entry" class="font-medium" />
		</CommonButton>
	</div>
</template>
